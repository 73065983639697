import Axios from "axios";
import paymentGateways from "@Phosfato/payment-gateways-client";
import error from "@/layouts/error";

export const state = () => ({
    activeView: false,
    addFullMemory: {
        sending: false,
        warn: {
            show: false,
            title: 'Opa...',
            message: 'Algo deu errado ao tentar adicionar essa memória.'
        },
        data: null,
    },
    sending: false,
    loading: false,
    loggedIn: false,
    loginRequired: false,
    nextTrigger: false,
    passResetHash: null,
    passResetMail: null,
    instagram_processing: false,
    facebook_processing: false,
    suspend_processing: false,
    facebookToken: null,
    uploadArea: false,
    photoAspect: 'medium',
    showPhoto: null,
    uploadHUB: false,
    webAppUrl: 'https://app.phosfato.com.br',
    phosAPIUrl: 'https://ipa.phosfato.com.br',
    externalCrashMsg: null,
    webview:false,
    journeys: {
        sending: false,
        signup: {
            plan: null,
            planType: null,
            coupon: null,
            hasCEP: false,
            validCEP: false,
            raffleDay: '??/??/????',
            agendaWorkday: null,
            step: 0,
            maxSteps: 3,
            warning: false,
            warningTitle: 'Ops...',
            warningErrors: [],
            warningCreator: null,
            cpf: null,
            hasAddress: false,
            cep: null,
            rua: null,
            numero: null,
            cidade: null,
            bairro: null,
            complemento: null,
            estado: null,
            ccredito: null,
            cdata: null,
            ccvv: null,
            cnome: null,
            ccpf: null,
            installment: 1,
            iuguToken: null,
            frete: null,
            logistics: null,
            hasCoupon: false,
            promoCoupon: false,
            promoCouponStatus: null,
            promoCouponMsg: null,
        },
        specialPlanSignup : {
            plan: null,
            planInfo: null,
            coupon: null,
            hasCEP: false,
            validCEP: false,
            raffleDay: '??/??/????',
            agendaWorkday: null,
            step: 0,
            maxSteps: 3,
            warning: false,
            warningTitle: 'Ops...',
            warningErrors: [],
            warningCreator: null,
            cpf: null,
            hasAddress: false,
            cep: null,
            rua: null,
            numero: null,
            cidade: null,
            bairro: null,
            complemento: null,
            estado: null,
            ccredito: null,
            cdata: null,
            ccvv: null,
            cnome: null,
            ccpf: null,
            installment: 1,
            iuguToken: null,
            frete: null,
            logistics: null,
            hasCoupon: false,
            promoCoupon: false,
            promoCouponStatus: null,
            promoCouponMsg: null,
        },
        giftcard: {
            giftcard: 'false',
            giftcardMsg: null,
            giftcardStatus: null,
            code: null,
            cupomData: null,
            step: 0,
            maxSteps: 3,
            hasCEP: false,
            validCEP: false,
            hasAddress: false,
            cep: null,
            rua: null,
            numero: null,
            cidade: null,
            bairro: null,
            complemento: null,
            estado: null,
            warning: false,
            warningTitle: 'Ops...',
            warningErrors: [],
            warningCreator: null,
        }
    },
    storeQuery: {
        next: false,
        logout: false,
        afterLogout: false,
        signup: false,
        section: false,
        cupom: false,
        register: false,
        passReset: false,
        PassRecover: false,
        plan: false,
        change: false,
        externalCrash: false,
        newInsta: false,
        fullscreen: false,
        app: false,
        originPp: false
    },
    modalControl: {
        open: false,
        type: 'alert',
        preventEscape: false,
        alert: {
            icon: 'phosficon-watchout',
            title: 'Ops...',
            call: null,
            text: 'Ocorreu um erro inesperado.',
            btn: 'OK!',
            color: 'warn'
        }
    },
    alert: {
        creator: null,
        title: 'Ops...',
        msg: 'Ocorreu um erro',
        btn: 'ok',
    },
    configOptions: {
        address: false,
        password: false,
        payment: false,
        plan: false,
        addressMsg: false,
        passwordMsg: false,
        paymentMsg: false,
        planMsg: false,
        loyaltyPlanHelp: false,
        loyaltyPlanHelpMsg: false,
        generalHelp: false,
        generalHelpMsg: false
    },
    captchaTokens: {
        register: null,
        signup: null,
        specialPlanSignup: null
    }
});

export const actions = {
    updateField({ commit }, payload) {
        commit('updateField', payload);
    },
    updateSignupField({ commit }, payload) {
        commit('updateSignupField', payload);
    },
    updateCaptcha({ commit }, payload) {
        commit('updateCaptcha', payload);
    },
    updateStoreQuery({ commit }, payload) {
        commit('updateStoreQuery', payload);
    },
    loading({ commit }, payload) {
        commit('updateField', { field: 'loading', value: payload });
    },
    setQuery({ state, commit }, payload) {
        commit('updateField', { field: 'storeQuery', value: payload });
    },
    setUrl({ commit }) {
        commit('updateField', { field: 'webAppUrl', value: process.env.WEBAPPURL+(process.env.WEBAPPPORT?':'+process.env.WEBAPPPORT:'')});
        commit('updateField', { field: 'phosAPIUrl', value: process.env.APIURL });
    },
    setNext({ commit }, payload) {
        commit('updateField', { field: 'next', value: payload });
    },
    setPlanEspUuid({commit}, {planEspUuid}) {
        commit('updateSpecialPlanSignupField', { field: 'planEspUuid', value: planEspUuid });
    },
    triggerNext({ commit }, boolean) {
        commit('updateField', { field: 'loading', value: true });
        commit('updateField', { field: 'nextTrigger', value: boolean });
    },
    requestLogin({ commit }) {
        commit('updateField', { field: 'loading', value: false });
        commit('updateField', { field: 'activeView' , value: 'login' });
    },
    loginUser({ state, dispatch }, payload) {
        dispatch('loading', true);
        Axios.post("/signIn", {
            user: payload.user,
            password: payload.password,
            remindMe: payload.remindMe
        })
            .then(response => {
                if (!response.data.error) {
                    dispatch('userData/checkUser', {
                        bearer: response.data.token_type+' '+response.data.access_token,
                        onError: payload.onError
                    }, { root: true });
                    this.$globalFuncs.firebaseProxy('event', 'login_sucesso')
                } else {
                    payload.onError('Por favor verifique suas informações');
                    this.$globalFuncs.firebaseProxy('event', 'login_falhou')
                }
            })
            .catch(error => {
                this.$errorPrint('/signIn', error);
                dispatch('requestLogin');
            })
            .finally(() => {
                dispatch('loading', false);
            }
            );
    },
    noUserRoutines({ state, commit, dispatch }) {
        dispatch('changeView', 'login');
        if (state.storeQuery.giftcard) {
            window.location = process.env.SITEURL + '/resgate?giftcard=' + state.storeQuery.giftcard;
        } else if (state.storeQuery.register) {
            dispatch('changeView', 'register');
            commit('updateStoreQuery', {field: 'register', value: false});
        } else if (state.storeQuery.passReset) {
            dispatch('changeView', 'passReset');
            commit('updateStoreQuery', {field: 'passReset', value: false});
        } else if (state.storeQuery.passRecover) {
            dispatch('setPassRecover', state.storeQuery.passRecover);
            commit('updateStoreQuery', {field: 'passRecover', value: false});
        } else if (state.storeQuery.plan) {
            commit('updateSignupField', {field: 'plan', value: state.storeQuery.plan});
            commit('updateStoreQuery', {field: 'plan', value: false});
        } else if (state.storeQuery.externalCrash) {
            commit('updateField', {field: 'externalCrashMsg', value: state.storeQuery.externalCrash});
            commit('updateStoreQuery', {field: 'externalCrash', value: false});
            window.location.href = process.env.WEBAPPURL + (process.env.WEBAPPPORT ? ':' + process.env.WEBAPPPORT : '') + '/warning/external/generic'
        }
        dispatch('userData/getCookieData', {}, {root: true});
    },
    logInRoutines({ rootState, state, commit, dispatch }) {
        let userData = rootState.userData;
        //delete
        dispatch('changeView', 'memories');
        if (state.storeQuery.next) {
            if (state.next!==state.storeQuery.next) {
                dispatch('setNext', state.storeQuery.next);
            }
            dispatch('triggerNext', true);
            commit('updateStoreQuery',{field:'next',value:false});
        } else {
            if (state.storeQuery.logout) {
                dispatch('userData/logOut', {}, { root: true });
                commit('updateStoreQuery',{field:'logout',value:false});
            } else if (state.storeQuery.passRecover) {
                dispatch('userData/logOut', {query: '?recoverpassword='+state.passResetHash+'&email='+state.passResetMail}, { root: true });
            } else {
                if (state.storeQuery.addFullMemory) {
                    dispatch('updateField', {field: 'data', parent: ['addFullMemory'], value: state.storeQuery.addFullMemory});
                    dispatch('openModal','add_full_memory');
                }
                if (state.storeQuery.cupom) {
                    commit('updateSignupField', {
                        field: "hasCoupon",
                        value: state.storeQuery.cupom
                    });
                    commit('updateStoreQuery',{field:'cupom',value:false});
                }
                if (state.storeQuery.signup) {
                    if (userData.hasAcceptedAgreement) {
                        dispatch('openModal', 'signup_query_anual');
                    } else {
                        dispatch('changeView', 'signup');
                    }
                    commit('updateStoreQuery',{field:'signup',value:false});
                }
                if (state.storeQuery.section) {
                    dispatch('changeView', state.storeQuery.section);
                    commit('updateStoreQuery',{field:'section',value:false});
                }
                if (state.storeQuery.giftcard || state.storeQuery.redeem) {
                    let code = state.storeQuery.giftcard ?? state.storeQuery.redeem;
                    if (code !== 'resgatar' && code !== 'digite_seu_giftcard' ) {
                        commit('updateGiftcardField', { field: 'code', value: code });
                        setTimeout(() => {dispatch('giftcardValidate');}, 500);
                    }
                    commit('updateStoreQuery',{field:'giftcard',value:false});
                    commit('updateStoreQuery',{field:'redeem',value:false});
                    dispatch('changeView', 'giftcard');
                }
                if (state.storeQuery.plan) {
                    commit('updateSignupField', { field: 'plan', value: state.storeQuery.plan });
                    commit('updateStoreQuery',{field:'plan',value:false});
                }
                if (state.storeQuery.planEspUuid) {
                    commit('updateSpecialPlanSignupField', { field: 'planEspUuid', value: state.storeQuery.planEspUuid });
                    commit('updateStoreQuery',{field:'planEspUuid',value:false});
                    dispatch('changeView', 'specialPlanSignup');
                }
                if (state.storeQuery.change) {
                    dispatch('changeView', 'account');
                    commit('updateStoreQuery',{field:'change',value:false});
                if (state.storeQuery.change === 'password') {
                    setTimeout(() => {
                        commit('toggleConfOptions', { field: state.storeQuery.change, value: true });
                    }, 700);
                    commit('updateStoreQuery',{field:'change',value:false});
                } else if (userData.is_member) {
                        if (userData.is_plan_active) {
                            setTimeout(() => {
                                commit('toggleConfOptions', { field: state.storeQuery.change, value: true });
                            }, 700);
                            commit('updateStoreQuery',{field:'change',value:false});
                        } else {
                            dispatch('setAlert', {
                                icon: 'phosficon-watchout',
                                text: 'Ops, seu plano parece estar suspenso, por favor reative para realizar alterações',
                                btn: 'Entendi'
                            });
                        }
                    } else {
                        dispatch('setAlert', {
                            icon: 'phosficon-watchout',
                            text: 'Ops, parece que você ainda não é assinante.',
                            btn: 'Entendi'
                        });
                    }
                } else if (
                    state.storeQuery.newInsta ||
                    (
                        (
                            rootState.userData.instagram_state === 0 ||
                            rootState.userData.instagram_state === 3
                        ) &&
                        rootState.userData.has_instagram
                    )
                ) {
                    dispatch('openModal','insta_resinc');
                    commit('updateStoreQuery',{field:'newInsta',value:false});
                }
            }
            dispatch('userData/fillState', {}, { root: true });
        }
    },
    registerUser({ state, commit, dispatch }, payload) {
        commit('updateField', { field: 'loading', value: true });
        payload.data.captchaToken = state.captchaTokens.register;
        payload.data.captchaDisable = (process.env.CAPTCHA_DISABLE == 'true');
        Axios.post('/register', payload.data)
            .then(response => {
                if (!response.data.error) {
                    this.$globalFuncs.firebaseProxy('event', 'registro_sucesso')
                    // dispatch('changeView', 'signup');
                    this.$fb.track('wa_register');
                    dispatch('userData/gtmPushEvent', { event: 'wa_register' }, { root: true });
                    dispatch('loginUser', {
                        user: payload.data.email,
                        password: payload.data.password,
                        remindMe: false
                    }
                    );
                } else {
                    let errorMsg = 'Ocorreu um erro inesperado, tente novamente em instantes';
                    if (response.data.msg) {
                        if (response.data.msg.message) {
                            errorMsg = response.data.msg.message;
                        } else if (
                            response.data.msg.errors &&
                            response.data.msg.errors.data &&
                            response.data.msg.errors.data.fields
                        ) {
                            let obj = response.data.msg.errors.data.fields;
                            errorMsg = obj[Object.keys(obj)[0]][0];
                        }
                    }
                    payload.sendWarn(errorMsg);
                    this.$globalFuncs.firebaseProxy('event', 'registro_falhou')
                }
            })
            .catch(error => {
                this.$errorPrint('/register', error);
            })
            .finally(() => {
                commit('updateField', { field: 'loading', value: false });
            }
            );
    },
    resetPassword({}, payload) {
        Axios.post('/resetpassword', { email: payload.email })
            .then(response => {
                payload.onSuccess();
            })
            .catch(error => {
                this.$errorPrint('/resetpassword', error);
                payload.onError();
            });
    },
    toggleFacebook({ state, commit, dispatch }, isConnected) {
        commit('updateField', { field: 'facebook_processing', value: true });
        let vm = this;
        this.$fbSDK.sdk.getLoginStatus(function (loginStatus) {
            if (loginStatus.status === "connected") {
                commit('updateField', {
                    field: 'facebookToken',
                    value: {
                        user_id: loginStatus.authResponse.userID,
                        access_token: loginStatus.authResponse.accessToken
                    }
                });
                dispatch('setFacebook', isConnected);
            } else {
                vm.$fbSDK.sdk.login(function (loginRes) {
                    if (loginRes.status === "connected") {
                        commit('updateField', {
                            field: 'facebookToken',
                            value: {
                                user_id: loginRes.authResponse.userID,
                                access_token: loginRes.authResponse.accessToken
                            }
                        });
                        dispatch('setFacebook', isConnected);
                    } else {
                        commit('updateField', { field: 'facebook_processing', value: false });
                    }
                }, { scope: 'email,user_photos' });
            }
        });
    },
    setFacebook({ state, dispatch }, isConnected) {
        if (!isConnected) {
            dispatch("logInFacebook", state.facebookToken);
        } else {
            dispatch("logOutFacebook");
        }
    },
    logInFacebook({ commit, dispatch }, payload) {
        Axios.post('/facebook',
            {
                action: 'login',
                payload: payload,
            })
            .then(response => {
                commit('updateField', { field: 'facebook_processing', value: false });
                location.reload();
            })
            .catch(error => {
                this.$errorPrint('/facebook logInFacebook', error);
            });
    },
    logOutFacebook({ commit, dispatch }, payload) {
        Axios.post('/facebook',
            {
                action: 'logout'
            })
            .then(response => {
                this.$fbSDK.sdk.getLoginStatus();
                document.location.reload(true);
            })
            .catch(error => {
                this.$errorPrint('/facebook logOutFacebook', error);
            });
    },
    disconectIG({ commit, dispatch }, payload) {
        commit('updateField', { field: 'instagram_processing', value: true });
        Axios.post('/instaredirect', { disconect: true })
            .then(response => {
                if (response.data.error && response.data.msg === 'Usuário não autenticado.') { location.reload(true); }
                commit('userData/updateField', { field: 'has_instagram', value: false }, { root: true });
                commit('updateField', { field: 'instagram_processing', value: false });
                dispatch(
                    'photos/refreshPhotos',
                    { onSuccess: () => { } },
                    { root: true });
                dispatch('photos/updatePhotoCount', {}, { root: true });
            })
            .catch(error => {
                this.$errorPrint('/instaredirect', error)
                commit('updateField', { field: 'instagram_processing', value: false });
            })
            .finally(final => {
                commit('igProcessing', false);
            });

    },
    toggleUploadArea({ commit }) {
        commit('toggleUploadArea');
    },
    openDropZone({state, commit, dispatch}) {
        commit('clearArea');
        commit('openUploadArea');
        dispatch('changeView', 'memories');
        dispatch('toggleUploadHUB', false);
        setTimeout(() => {
            let mdp = document.getElementById('mainDropZone');
            console.log(mdp)
            if (mdp) {
                mdp.click();
            }
        }, 200)
    },
    addFullMemory({state, commit, dispatch}, payload) {
        commit('updateField', {field: 'sending', parent: ['addFullMemory'], value: true});
        let photoData = {
            photo_url: state.addFullMemory.data.image
        }
        if (state.addFullMemory.data && state.addFullMemory.data.caption) {
            if (state.addFullMemory.data.caption.date) {
                photoData.date = state.addFullMemory.data.caption.date;
            }
            if (state.addFullMemory.data.caption.likes) {
                photoData.likes = state.addFullMemory.data.caption.likes;
            }
            if (state.addFullMemory.data.caption.caption) {
                photoData.caption = state.addFullMemory.data.caption.caption;
            }
        }
        if (state.addFullMemory.data.crop) {
            photoData.crop = state.addFullMemory.data.crop;
        }
        this.$api.$post('/v1/me/photos/add', photoData)
          .then(response => {
              if (response.success) {
                  dispatch("photos/refreshPhotos", {}, { root: true });
                  dispatch("closeAllModal");
                  commit('updateField', {field: 'data', parent: ['addFullMemory'], value: null});
                  commit('updateField', {field: 'sending', parent: ['addFullMemory'], value: false});
                  dispatch("photos/refreshPhotos", {}, { root: true });
              } else {
                  commit('updateField', {field: 'sending', parent: ['addFullMemory'], value: false});
                  let msg = 'Erro '+response.status+': '+(response.data.message?response.data.message:'Algo deu errado ao tentar adicionar essa memória.')
                  commit('updateField', {field: 'show', parent: ['addFullMemory', 'warn'], value: true });
                  commit('updateField', {field: 'message', parent: ['addFullMemory', 'warn'], value: msg });
                  commit('updateField', {field: 'title', parent: ['addFullMemory', 'warn'], value: state.addFullMemory.warn.title });
              }
          })
          .catch(error => {
              commit('updateField', {field: 'sending', parent: ['addFullMemory'], value: false});
              let msg = 'Erro '+error.response.status+': '+(error.response.data.message?error.response.data.message:'Algo deu errado ao tentar adicionar essa memória.')
              commit('updateField', {field: 'show', parent: ['addFullMemory', 'warn'], value: true });
              commit('updateField', {field: 'message', parent: ['addFullMemory', 'warn'], value: msg });
              commit('updateField', {field: 'title', parent: ['addFullMemory', 'warn'], value: state.addFullMemory.warn.title });
          })
    },
    getPhotos({ commit }, payload) {
        commit('getPhotos');
    },
    changePhotoSize({ commit }, payload) {
        commit('changePhotoSize', payload);
    },
    changeView({ rootState, state, commit }, view) {
        if (state.webview) {
            if (view === 'signup') {
                window.location.href = '/pp/sub'
            } else if (view === 'giftcard') {
                window.location.href = '/webview/openGiftcard'
            } else {
                window.location.href = '/webview/cancel'
            }
        } else {
            if (view === 'signup' && state.activeView !== view) {
                if (rootState.userData.is_member) {
                    if (rootState.userData.is_plan_active) {
                        this.$globalFuncs.firebaseProxy('event', 'iniciou_jor_migracao')
                    } else {
                        this.$globalFuncs.firebaseProxy('event', 'iniciou_jor_reativacao')
                    }
                } else {
                    this.$globalFuncs.firebaseProxy('event', 'iniciou_jor_assinatura')
                }
                return window.location.href = '/pp/sub'
            }
            commit('updateField', { field: 'activeView', value: view });
        }
    },
    openModal({ commit }, payload) {
        commit('createModal', { type: payload });
    },
    setPreventEscape({ commit }, payload) {
        commit('preventEscape', payload);
    },
    suspendPlan({ commit, dispatch }) {
        commit('updateField', { field: 'suspend_processing', value: true });
        Axios.post('/suspend', {})
            .then(response => {
                dispatch('closeAllModal');
                dispatch('userData/getUserData', {}, { root: true });
                commit('updateField', { field: 'suspend_processing', value: false });
                dispatch('userData/gtmPushEvent', { event: 'wa_suspend' }, { root: true });
            })
            .catch(error => {
                this.$errorPrint('/suspendPlan', error);
                commit('updateField', { field: 'suspend_processing', value: false });
            });
    },
    reactivatePlan({ commit, dispatch }, payload) {
        commit('updateField', { field: 'suspend_processing', value: true });
        Axios.post('/buscafrete', {
            cep: payload.cep,
            plan_id: payload.plan_id
        })
            .then(response => {
                Axios.post('/updateplan', {
                    plan_id: payload.plan_id,
                    logistics: parseInt(response.data.data.shipping[0].service.id)
                })
                    .then(response => {
                        dispatch('userData/getUserData', {}, { root: true });
                        commit('updateField', { field: 'suspend_processing', value: false });
                        dispatch('userData/gtmPushEvent', { event: 'wa_reactivate' }, { root: true });
                    })
                    .catch(error => {
                        this.$errorPrint('/updateplan', error);
                        commit('updateField', { field: 'suspend_processing', value: false });
                    });
            })
            .catch(error => {
                this.$errorPrint('/buscafrete', error);
            });
    },
    sendMessage({ rootState, state, dispatch }, payload) {
        Axios.post("/sendmessage", {
            name: payload.name || rootState.userData.name,
            email: payload.email || rootState.userData.email,
            message: payload.message,
        })
            .then(response => {
                let res = (response.data.data)?response.data:response;
                if (!res.error) {
                    if(payload.onSuccess) payload.onSuccess(res.data);
                } else {
                    if(payload.onError) payload.onError(res.data);
                }
            })
            .catch(error => {
                if(payload.onError) payload.onError(error.response.data);
            })
    },
    closeAllModal({ commit }) {
        commit('closeAllModal');
    },
    toggleConfOptions({ state, commit }, payload) {
        if (!state.configOptions[payload.field]) {
            commit('closeConfOptions');
            commit('toggleConfOptions', { field: payload.field, value: true });
        } else {
            commit('closeConfOptions');
        }
    },
    setJourneyAddress({commit}, address) {
        let journeys = ['updateSignupField', 'updateSpecialPlanSignupField', 'updateGiftcardField'];
        journeys.forEach((journey) => {
            commit(journey, { field: 'cep', value: address.cep });
            commit(journey, { field: 'rua', value: address.address });
            commit(journey, { field: 'numero', value: address.number });
            commit(journey, { field: 'bairro', value: address.district });
            commit(journey, { field: 'cidade', value: address.city });
            commit(journey, { field: 'estado', value: address.state });
            commit(journey, { field: 'complemento', value: address.complement });
            commit(journey, { field: 'hasAddress', value: true });
        })
    },
    setJourneyFieldByView({state, commit}, payload) {
        switch (state.activeView) {
            case 'signup':
                commit('updateSignupField', payload);
                break;
            case 'specialPlanSignup':
                commit('updateSpecialPlanSignupField', payload);
                break;
            case 'giftcard':
                commit('updateGiftcardField', payload);
                break;
        }
    },
    sendStepByView({state, dispatch}, payload) {
        switch (state.activeView) {
            case 'signup':
                dispatch('sendSignupStep', payload);
                break;
            case 'specialPlanSignup':
                dispatch('sendSpecialPlanSignupStep', payload);
                break;
        }
    },
    sendSignupStep({ rootState, state, commit, dispatch }, value) {
        if (value >= 0 && value <= state.journeys.signup.step) {
            commit('setSignupStep', value);
        }
        if (value === '-') {
            if (state.journeys.signup.step !== 0) {
                commit('setSignupStep', value);
                dispatch('signupWarning', { msg: false });
            }
        } else if (value === '+') {
            if (state.journeys.signup.step === 0) {
                if (state.journeys.signup.hasAddress) {
                    dispatch('buscaFrete', {journey: 'signup'});
                }
                if (state.journeys.signup.plan && state.journeys.signup.plan !== null) {
                    commit('setSignupStep', value);
                }
            } else if (state.journeys.signup.step === 1) {
                dispatch('signupWarning', { msg: false });
                commit('setSignupStep', value);
            } else if (state.journeys.signup.step === 2) {
                if (!state.journeys.signup.ccredito || state.journeys.signup.ccredito.length < 14) {
                    dispatch('signupWarning', { msg: ['Número de cartão inválido'], creator:'payment' });
                } else if (!state.journeys.signup.cdata) {
                    dispatch('signupWarning', { msg: ['Insira a data de expiração'], creator:'payment' });
                } else if (!(
                  /(0[1-9]|10|11|12)\/(20[0-9]{2}|0[1-9]|[1-9][0-9])/.test(state.journeys.signup.cdata) &&
                  (
                    state.journeys.signup.cdata.length === 5 ||
                    state.journeys.signup.cdata.length === 7)
                )
                ) {
                    dispatch('signupWarning', { msg: ['Data de expiração invalida'], creator:'payment' });
                } else if (!state.journeys.signup.ccvv) {
                    dispatch('signupWarning', { msg: ['Insira o CVV'], creator:'payment' });
                } else if (!state.journeys.signup.cnome) {
                    dispatch('signupWarning', { msg: ['Insira o nome do titular do cartão'], creator:'payment' });
                } else if (!this.$globalFuncs.validateCpf(state.journeys.signup.ccpf)) {
                    dispatch('signupWarning', { msg: ['Insira um CPF válido'], creator:'payment' });
                } else {
                    dispatch('signupWarning', { msg: false });
                    let cleanCred = state.journeys.signup.ccredito.replace(new RegExp(" ", "g"), '');
                    let ccyear = parseInt(state.journeys.signup.cdata.split('/')[1]);
                    if (ccyear < 1000) {
                        ccyear = 2000 + ccyear;
                    }
                    let nameArray = state.journeys.signup.cnome.split(' ');
                    let fname = nameArray[0];
                    nameArray.splice(0, 1);
                    let lname = nameArray.join(' ');
                    dispatch('getTokens', {
                        data: {
                            card: {
                                number: cleanCred,
                                cvv: state.journeys.signup.ccvv,
                                fname: fname,
                                lname: lname,
                                month: state.journeys.signup.cdata.split('/')[0],
                                year: ccyear.toString()
                            },
                            gateways: [
                                {
                                    name: 'iugu',
                                    required: true
                                },
                                {
                                    name: 'asaas',
                                    required: false
                                }
                            ]
                        }
                    });
                }
            }
        }
        window.scrollTo(0, 0);
    },
    sendSpecialPlanSignupStep({ rootState, state, commit, dispatch }, value) {
        if (value >= 0) {
            commit('setSpecialPlanSignupStep', value);
        }
        if (value === '-') {
            if (state.journeys.specialPlanSignup.step !== 0) {
                commit('setSpecialPlanSignupStep', value);
                dispatch('specialPlanSignupWarning', { msg: false });
            }
        } else if (value === '+') {
            if (state.journeys.specialPlanSignup.step === 0) {
                if (state.journeys.specialPlanSignup.hasAddress) {
                    dispatch('buscaCEP', {
                        cep: state.journeys.specialPlanSignup.cep,
                        journey: 'specialPlanSignup',
                    });
                }
                commit('setSpecialPlanSignupStep', value);
            } else if (state.journeys.specialPlanSignup.step === 1) {
                dispatch('specialPlanSignupWarning', { msg: false });
                commit('setSpecialPlanSignupStep', value);
            } else if (state.journeys.specialPlanSignup.step === 2) {
                if (!state.journeys.specialPlanSignup.ccredito || state.journeys.specialPlanSignup.ccredito.length < 14) {
                    dispatch('specialPlanSignupWarning', { msg: ['Número de cartão inválido'], creator:'payment' });
                } else if (!state.journeys.specialPlanSignup.cdata) {
                    dispatch('specialPlanSignupWarning', { msg: ['Insira a data de expiração'], creator:'payment' });
                } else if (!(
                  /(0[1-9]|10|11|12)\/(20[0-9]{2}|0[1-9]|[1-9][0-9])/.test(state.journeys.specialPlanSignup.cdata) &&
                  (
                    state.journeys.specialPlanSignup.cdata.length === 5 ||
                    state.journeys.specialPlanSignup.cdata.length === 7)
                )
                ) {
                    dispatch('specialPlanSignupWarning', { msg: ['Data de expiração invalida'], creator:'payment' });
                } else if (!state.journeys.specialPlanSignup.ccvv) {
                    dispatch('specialPlanSignupWarning', { msg: ['Insira o CVV'], creator:'payment' });
                } else if (!state.journeys.specialPlanSignup.cnome) {
                    dispatch('specialPlanSignupWarning', { msg: ['Insira o nome do titular do cartão'], creator:'payment' });
                } else if (!this.$globalFuncs.validateCpf(state.journeys.specialPlanSignup.ccpf)) {
                    dispatch('specialPlanSignupWarning', { msg: ['Insira um CPF válido'], creator:'payment' });
                } else {
                    dispatch('specialPlanSignupWarning', { msg: false });
                    let cleanCred = state.journeys.specialPlanSignup.ccredito.replace(new RegExp(" ", "g"), '');
                    let ccyear = parseInt(state.journeys.specialPlanSignup.cdata.split('/')[1]);
                    if (ccyear < 1000) {
                        ccyear = 2000 + ccyear;
                    }
                    let nameArray = state.journeys.specialPlanSignup.cnome.split(' ');
                    let fname = nameArray[0];
                    nameArray.splice(0, 1);
                    let lname = nameArray.join(' ');
                    dispatch('getTokens', {
                        data: {
                            card: {
                                number: cleanCred,
                                cvv: state.journeys.specialPlanSignup.ccvv,
                                fname: fname,
                                lname: lname,
                                month: state.journeys.specialPlanSignup.cdata.split('/')[0],
                                year: ccyear.toString()
                            },
                            gateways: [
                                {
                                    name: 'iugu',
                                    required: true
                                },
                                {
                                    name: 'asaas',
                                    required: false
                                }
                            ]
                        }
                    });
                }
            }
        }
        window.scrollTo(0, 0);
    },
    async buscaCEP({ state, commit, dispatch }, payload) {
        let flags = {};
        switch (payload.journey) {
            case 'signup':
                flags = {
                    action: 'updateSignupField',
                    frete: true
                };
                break;
            case 'specialPlanSignup':
                flags = {
                    action: 'updateSpecialPlanSignupField',
                    frete: true
                };
                break;
            case 'giftcard':
                flags = {
                    action: 'updateGiftcardField',
                    frete: false
                };
                break;
        }
        commit(flags.action, { field: 'cep', value: payload.cep });
        if (payload.cep && payload.cep.length === 9) {
            let value = payload.cep.replace('-', '');
            commit(flags.action, { field: 'validCEP', value: true });
            let url = "https://viacep.com.br/ws/" + value + "/json";
            let endereco;
            try {
                endereco = await this.$axios.$get(url);
            } catch (e) {
                console.log('Erro viacep: ', e);
            }
            if (!endereco.erro) {
                commit(flags.action, { field: 'rua', value: endereco.logradouro });
                commit(flags.action, { field: 'bairro', value: endereco.bairro });
                commit(flags.action, { field: 'cidade', value: endereco.localidade });
                commit(flags.action, { field: 'estado', value: endereco.uf });
                commit(flags.action, { field: 'hasCEPClass', value: '' });
                commit(flags.action, { field: 'validCEP', value: true });
                if(payload.onSuccess) { payload.onSuccess(); }
            } else {
                if(payload.onError) { payload.onError(); }
            }
            if (flags.frete) { dispatch('buscaFrete', { journey: payload.journey }); }
        } else {
            commit(flags.action, { field: 'validCEP', value: false });
        }
    },
    buscaFrete({ state, commit }, payload) {
        let flags = {};
        switch (state.activeView) {
            case 'signup':
                flags = {
                    action: 'updateSignupField'
                };
                break;
            case 'specialPlanSignup':
                flags = {
                    action: 'updateSpecialPlanSignupField'
                };
                break;
        }
        Axios.post('/buscafrete', {
            cep: state.journeys[state.activeView].cep,
            plan_id: state.journeys[state.activeView].plan
        })
            .then(response => {
                let frete = parseFloat(response.data.data.shipping[0].price);
                let logistics = parseInt(response.data.data.shipping[0].service.id);
                commit(flags.action, { field: 'frete', value: frete });
                commit(flags.action, { field: 'logistics', value: logistics });
            })
            .catch(error => {
                this.$errorPrint('/buscafrete', error);
            });
    },
    buscaCupom({ state, dispatch }, payload) {
        dispatch('updateCouponInfo', { status: 'validating', coupon: false, msg: 'Validando'});
        if (payload) {
            Axios.post('/buscacupom', {
                name: payload.toUpperCase(),
                plan_id: state.journeys.signup.plan
            })
              .then(response => {
                  if (response.data.error) {
                      dispatch('updateCouponInfo', { status: 'error', coupon: false, msg: 'Código de indicação inválido'});
                      setTimeout(() => {
                          dispatch('updateCouponInfo');
                      }, 2000);
                  } else {
                      dispatch('updateCouponInfo', { status: 'success', coupon: response.data, msg: false});
                  }
              })
              .catch(error => {
                  this.$errorPrint('/buscacupom', error);
                  dispatch('updateCouponInfo', { status: 'error', coupon: false, msg: 'Ops... Algo deu errado...'});
                  setTimeout(() => {
                      dispatch('updateCouponInfo');
                  }, 2000);
              });
        } else {
            dispatch('updateCouponInfo', { status: 'error', coupon: false, msg: 'Insira um código de indicação'});
            setTimeout(() => {
                dispatch('updateCouponInfo');
            }, 2000);
        }
    },
    buscaCupomSpecial({ state, dispatch }, payload) {
        dispatch('updateSpecialCouponInfo', { status: 'validating', coupon: false, msg: 'Validando'});
        if (payload) {
            Axios.post('/buscacupom', {
                name: payload.toUpperCase(),
                plan_id: state.journeys.specialPlanSignup.plan
            })
              .then(response => {
                  if (response.data.error) {
                      dispatch('updateSpecialCouponInfo', { status: 'error', coupon: false, msg: 'Código de indicação inválido'});
                      setTimeout(() => {
                          dispatch('updateSpecialCouponInfo');
                      }, 2000);
                  } else {
                      dispatch('updateSpecialCouponInfo', { status: 'success', coupon: response.data, msg: false});
                  }
              })
              .catch(error => {
                  this.$errorPrint('/buscacupom', error);
                  dispatch('updateSpecialCouponInfo', { status: 'error', coupon: false, msg: 'Ops... Algo deu errado...'});
                  setTimeout(() => {
                      dispatch('updateSpecialCouponInfo');
                  }, 2000);
              });
        } else {
            dispatch('updateSpecialCouponInfo', { status: 'error', coupon: false, msg: 'Insira um código de indicação'});
            setTimeout(() => {
                dispatch('updateSpecialCouponInfo');
            }, 2000);
        }
    },
    updateCouponInfo({ commit }, payload) {
        if (payload && payload.status) {
            commit('updateSignupField', { field: 'promoCouponStatus', value: payload.status });
            commit('updateSignupField', { field: 'promoCouponMsg', value: payload.msg });
            commit('updateSignupField', { field: 'promoCoupon', value: payload.coupon });
        } else {
            commit('updateSignupField', { field: 'promoCouponStatus', value: null });
            commit('updateSignupField', { field: 'promoCouponMsg', value: null });
            commit('updateSignupField', { field: 'promoCoupon', value: false });
        }
    },
    updateSpecialCouponInfo({ commit }, payload) {
        if (payload && payload.status) {
            commit('updateSpecialPlanSignupField', { field: 'promoCouponStatus', value: payload.status });
            commit('updateSpecialPlanSignupField', { field: 'promoCouponMsg', value: payload.msg });
            commit('updateSpecialPlanSignupField', { field: 'promoCoupon', value: payload.coupon });
        } else {
            commit('updateSpecialPlanSignupField', { field: 'promoCouponStatus', value: null });
            commit('updateSpecialPlanSignupField', { field: 'promoCouponMsg', value: null });
            commit('updateSpecialPlanSignupField', { field: 'promoCoupon', value: false });
        }
    },
    giftcardValidate({ rootState, state, commit, dispatch }, payload) {
        let validateUrl = process.env.APIURL + '/v1/gift-card/validate'
        dispatch('updateGiftcardInfo', { status: 'validating', coupon: false, msg: 'Validando'});
        if (state.journeys.giftcard.code && state.journeys.giftcard.code !== '') {
            Axios.post(
                validateUrl,
                {
                    code: state.journeys.giftcard.code
                },
                {
                    headers: {
                        Authorization: rootState.userData.bearer
                    }
                }
            )
                .then(response => {
                    switch (response.data.data.status) {
                        case "available":
                            commit('updateGiftcardField', {field: 'cupomData', value: response.data.data});
                            dispatch('updateGiftcardInfo', {status: 'success', msg: false});
                            break;
                        case 'expired':
                            dispatch('updateGiftcardInfo', {status: 'error', msg: response.data.data.errors.message});
                            commit('updateGiftcardField', {field: 'cupomData', value: false});
                            break;
                        case 'redeemed':
                            dispatch('updateGiftcardInfo', {status: 'error', msg: response.data.data.errors.message});
                            commit('updateGiftcardField', {field: 'cupomData', value: false});
                            break;
                    }

                })
                .catch(error => {
                    if (error.response) {
                        dispatch('updateGiftcardInfo', {status: 'error', msg: error.response.data.errors.message});
                        commit('updateGiftcardField', {field: 'cupomData', value: false});
                    } else {
                        dispatch('updateGiftcardInfo', {status: 'error', msg: "Falha ao validar o código."});
                        commit('updateGiftcardField', {field: 'cupomData', value: false});
                    }
                    setTimeout(() => {
                        dispatch('updateGiftcardInfo');
                    }, 5000);
                })
        } else {
            dispatch('updateGiftcardInfo', { status: 'error', coupon: false, msg: 'Insira seu código de gift card'});
            setTimeout(() => {
                dispatch('updateGiftcardInfo');
            }, 5000);
        }
    },
    giftcardRedeem({ rootState, state, commit, dispatch }) {
        commit('updateSending', 'finishing'  );
        let validateUrl = process.env.APIURL + '/v1/gift-card/redeem'
        let errorMsg = { msg: ['Ocorreu um erro ao tentar regatar o giftcard'], creator:'address' };
        Axios.post(
            validateUrl,
            {
                code: state.journeys.giftcard.code,
                address: {
                    address: state.journeys.giftcard.rua,
                    number: state.journeys.giftcard.numero,
                    complement: state.journeys.giftcard.complemento,
                    district: state.journeys.giftcard.bairro,
                    city: state.journeys.giftcard.cidade,
                    state: state.journeys.giftcard.estado,
                    cep: state.journeys.giftcard.cep
                }
            },
            {
                headers: {
                    Authorization: rootState.userData.bearer
                }
            }
        )
            .then(response => {
                if (response.data.success) {
                    dispatch('userData/getUserData', {}, {root: true})
                    dispatch('userData/getPaymentData', {}, {root: true})
                    commit('updateSending', 'complete'  );
                    if (rootState.userData.visualIdentityModifier === 'jockey') {
                        setTimeout(() => {
                            dispatch('changeView', 'memories');
                            dispatch('openModal', 'jockeyFinish');
                            dispatch('updateGiftcardInfo');
                            commit('updateGiftcardField', { field: 'step', value: 0 });
                            commit('updateGiftcardField', { field: 'code', value: false });
                            commit('updateSending', null  );
                        }, 5000)
                    } else {
                        if (state.webview) {
                            window.location.href = '/webview/success';
                        }
                        setTimeout(() => {
                            dispatch('changeView', 'memories');
                            dispatch('openModal', 'giftcardFinish');
                            dispatch('updateGiftcardInfo');
                            commit('updateGiftcardField', { field: 'step', value: 0 });
                            commit('updateGiftcardField', { field: 'code', value: false });
                            commit('updateSending', null  );
                        }, 5000)
                    }
                } else {
                    dispatch('giftcardWarning', { msg: [response.data.data.errors.message]});
                }
            })
            .catch(error => {
                dispatch('giftcardWarning', { msg: [error.response && error.response.data.errors.message]});
            })
            dispatch('userData/getUserData', {}, {root: true})
    },
    updateGiftcardInfo({ commit }, payload) {
        if (payload && payload.status) {
            commit('updateGiftcardField', { field: 'giftcardStatus', value: payload.status });
            commit('updateGiftcardField', { field: 'giftcardMsg', value: payload.msg });
        } else {
            commit('updateGiftcardField', { field: 'giftcardStatus', value: null });
            commit('updateGiftcardField', { field: 'giftcardMsg', value: null });
        }
    },
    getTokens({ state, commit, dispatch }, payload) {
        if (state.activeView === 'signup') {
            commit('updateSending', 'card'  );
        } else if (state.activeView === 'specialPlanSignup') {
            commit('updateSending', 'card'  );
        } else {
            commit('toggleConfOptions', { field: 'paymentMsg', value: 'Validando dados' });
        }
        payload.Axios = Axios
        paymentGateways.gatherTokens(
            payload,
            (response) => dispatch('getTokensResponse', response),
        );
    },
    getTokensResponse({ state, commit, dispatch }, response) {
        let flags = {};
        switch (state.activeView) {
            case 'specialPlanSignup':
                flags = {
                    action: 'updateSpecialPlanSignupField',
                    field: 'specialPlanSignup ',
                    warn: 'specialPlanSignupWarning'
                };
                break;
            default:
                flags = {
                    action: 'updateSignupField',
                    field: 'signup',
                    warn: 'signupWarning'
                };
                break;
        }
        if (!response.error) {
            let iuguResponse = response.data.gateways.iugu;
            dispatch(flags.warn, { msg: false });
            commit(flags.action, { field: 'iuguToken', value: iuguResponse.id });
            commit(flags.action, { field: 'card', value: response.data });
            this.$globalFuncs.firebaseProxy('event', 'sucesso_validar_cartao');
            if (state.activeView === 'signup' || state.activeView === 'specialPlanSignup') {
                dispatch('signup');
            } else {
                dispatch('updatePayment');
            }
            dispatch('userData/getPaymentData', {}, { root: true });
        } else {
            setTimeout(() => {
                commit('toggleConfOptions', { field: 'paymentMsg', value: false });
            }, 2000);
            if (state.activeView === 'signup' || state.activeView === 'specialPlanSignup') {
                dispatch(flags.warn, { msg: response.data, creator: 'payment' });
            } else {
                if (typeof response.data[Symbol.iterator] === 'function') {
                    response.data.forEach(error => {
                        dispatch(flags.warn, { msg: error, creator: 'payment', 'dontJump': true });
                    })
                } else if (this.$globalFuncs.getErrorMsg(response.data)) {
                    dispatch(flags.warn, { msg: this.$globalFuncs.getErrorMsg(response.data) , creator: 'payment', 'dontJump': true });
                } else {
                    dispatch(flags.warn, { msg: 'Ocorreu um erro inesperado', creator: 'payment', 'dontJump': true });
                }
            }
            commit('updateSending', false  );
        }
    },
    signupWarning({ commit }, payload) {
        let msg = payload.msg;
        if (msg) {
            if (typeof msg === 'String') {
                if (msg === 'Pagamento não autorizado.' || 'Ocorreu um erro com o serviço de pagamento') {
                    msg += ' Verifique limites, bloqueios, dados, se é habilitado para compra online e tente novamente.';
                } else if (msg === 'Ocorreu um erro no módulo de pagamento, tente mais tarde.') {
                    msg = 'Ocorreu um erro no módulo de pagamento. Verifique limites, bloqueios, dados, se é habilitado para compra online e tente novamente.';
                }
            } else if (msg instanceof Set) {
                console.log(msg);
                if (
                  msg.has('Pagamento não autorizado.') ||
                  msg.has('Ocorreu um erro com o serviço de pagamento')
                ) {
                    msg.add('Verifique limites, bloqueios, dados, se é habilitado para compra online e tente novamente.');
                } else if (msg.has('Ocorreu um erro no módulo de pagamento, tente mais tarde.')) {
                    msg.remove('Ocorreu um erro no módulo de pagamento, tente mais tarde.');
                    msg.add('Ocorreu um erro no módulo de pagamento. Verifique limites, bloqueios, dados, se é habilitado para compra online e tente novamente.');
                }
            } else if (Symbol.iterator in Object(msg)) {
                msg.forEach((entry, index) => {
                    if (
                      entry === 'Pagamento não autorizado.' ||
                      entry === 'Ocorreu um erro com o serviço de pagamento'
                    ) {
                        msg[index] = 'Pagamento não autorizado. Verifique limites, bloqueios, dados, se é habilitado para compra online e tente novamente.';
                    } else if (entry === 'Ocorreu um erro no módulo de pagamento, tente mais tarde.') {
                        msg[index] = 'Ocorreu um erro no módulo de pagamento. Verifique limites, bloqueios, dados, se é habilitado para compra online e tente novamente.';
                    }
                })
            }
            commit('updateSignupField', { field: "warning", value: true });
            commit('updateSignupField', { field: "warningErrors", value: msg });
            commit('updateSignupField', { field: "warningCreator", value: payload.creator });
            if (payload.title) {
                commit('updateSignupField', { field: "warningTitle", value: payload.title });
            }
            if (!payload.dontJump) {
                window.scrollTo(0, 0);
            }
        } else {
            commit('updateSignupField', { field: "warning", value: false });
            commit('updateSignupField', { field: "warningCreator", value: false });
            commit('updateSignupField', { field: "warningErrors", value: [] });
        }
    },
    specialPlanSignupWarning({ commit }, payload) {
        if (payload.msg) {
            commit('updateSpecialPlanSignupField', { field: "warning", value: true });
            commit('updateSpecialPlanSignupField', { field: "warningErrors", value: payload.msg });
            commit('updateSpecialPlanSignupField', { field: "warningCreator", value: payload.creator });
            if (payload.title) {
                commit('updateSpecialPlanSignupField', { field: "warningTitle", value: payload.title });
            }
            if (!payload.dontJump) {
                window.scrollTo(0, 0);
            }
        } else {
            commit('updateSpecialPlanSignupField', { field: "warning", value: false });
            commit('updateSpecialPlanSignupField', { field: "warningCreator", value: false });
            commit('updateSpecialPlanSignupField', { field: "warningErrors", value: [] });
        }
    },
    giftcardWarning({ commit }, payload) {
        if (payload.msg) {
            commit('updateGiftcardField', { field: "warning", value: true });
            commit('updateGiftcardField', { field: "warningErrors", value: payload.msg });
            commit('updateGiftcardField', { field: "warningCreator", value: payload.creator });
            if (payload.title) {
                commit('updateGiftcardField', { field: "warningTitle", value: payload.title });
            }
            if (!payload.dontJump) {
                window.scrollTo(0, 0);
            }
        } else {
            commit('updateSignupField', { field: "warning", value: false });
            commit('updateSignupField', { field: "warningCreator", value: false });
            commit('updateSignupField', { field: "warningErrors", value: [] });
        }
    },
    updatePayment({ state, commit, dispatch }) {
        Axios.post('/updatepayment', {
            iuguID: state.journeys.signup.iuguToken,
            card: state.journeys.signup.card
        })
            .then(response => {
                if (response.data.error) {
                    commit('toggleConfOptions', { field: 'paymentMsg', value: "Ocorreu um erro, tente novamente" });
                    setTimeout(() => {
                        commit('toggleConfOptions', { field: 'paymentMsg', value: false });
                    }, 2500);
                    commit('updateSignupField', { field: "warning", value: true });
                    commit('updateSignupField', { field: "warningCreator", value: 'payment' });
                    if (typeof this.$globalFuncs.getErrorMsg(response.data) === 'object') {
                        commit('updateSignupField', { field: "warningErrors", value: this.$globalFuncs.getErrorMsg(response.data) });
                    } else {
                        commit('updateSignupField', { field: "warningErrors", value: this.$globalFuncs.getErrorMsg(response.data.msg) });
                    }
                } else {
                    commit('toggleConfOptions', { field: 'paymentMsg', value: "Dados alterados" });
                    setTimeout(() => {
                        commit('toggleConfOptions', { field: 'paymentMsg', value: false });
                        commit('closeConfOptions');
                    }, 2000);
                    commit('updateSignupField', { field: "warning", value: false });
                    commit('updateSignupField', { field: "warningCreator", value: false });
                    commit('updateSignupField', { field: "warningErrors", value: [] });
                    dispatch('userData/getUserData', {}, { root: true });
                }
            })
            .catch(error => {
                this.$errorPrint('/updatepayment', error);
            }).finally(onFinally => {
            dispatch('userData/getPaymentData', {}, { root: true });
        });
    },
    updateAddress({ commit, dispatch }, payload) {
        // payload = {
        //     data: {
        //         address: 'string',
        //         number: 'int',
        //         district: 'string',
        //         city: 'string',
        //         state: 'string',
        //         cep: 'string',
        //         logistics_service_id: 'string',
        //     },
        //     onSuccess: () => {},
        //     onError: () => {},
        // }
        Axios.post('/updateaddress', payload.data)
            .then(response => {
                if (!response.data.error) {
                    payload.onSuccess(response);
                } else {
                    payload.onError(response.data);
                    this.$errorPrint('/updateaddress', response.data);
                }
                dispatch('userData/getUserData', {}, { root: true });
            })
            .catch(error => {
                payload.onError(error);
                this.$errorPrint('/updateaddress', error);
            });
    },
    signup({ rootState, state, commit, dispatch }) {
        let flags = {};
        switch (state.activeView) {
            case 'specialPlanSignup':
                const planInfo = state.journeys.specialPlanSignup.planInfo
                const installmentsAllowed = planInfo.config.agreement_type === 'one-time-buy' || planInfo.config.loyalty_plan
                flags = {
                    action: 'updateSpecialPlanSignupField',
                    warn: 'specialPlanSignupWarning',
                    installmentsBySelectedPlan: installmentsAllowed?state.journeys.specialPlanSignup.installment:1,
                    clearCoupon: () => {
                        dispatch('updateSpecialCouponInfo');
                    },
                    shipping: state.journeys.specialPlanSignup.frete,
                    plan: state.journeys.specialPlanSignup.planInfo
                };
                break;
            default:
                flags = {
                    action: 'updateSignupField',
                    warn: 'signupWarning',
                    installmentsBySelectedPlan: (rootState.plans.plansByID[state.journeys.signup.plan].config.loyalty_plan)?state.journeys.signup.installment:1,
                    clearCoupon: () => {
                        dispatch('updateCouponInfo');
                    },
                    shipping: state.journeys.signup.frete,
                    plan: rootState.plans.plansByID[state.journeys[state.activeView].plan]
                };
                break;
        }
        commit('updateSending', 'signup'  );

        let signup = {
            plan_id: state.journeys[state.activeView].plan,
            installments: flags.installmentsBySelectedPlan,
            payment_token: state.journeys[state.activeView].iuguToken,
            card: state.journeys[state.activeView].card,
            agenda_workday: rootState.userData.raffleDate.workday,
            cpf: state.journeys[state.activeView].ccpf,
            logistics_service_id: state.journeys[state.activeView].logistics,
            captchaToken: state.captchaTokens[state.activeView],
            captchaDisable: process.env.CAPTCHA_DISABLE || false,
            promo_coupon_id: null,
            address: {
                address: state.journeys[state.activeView].rua,
                number: state.journeys[state.activeView].numero,
                complement: state.journeys[state.activeView].complemento,
                district: state.journeys[state.activeView].bairro,
                city: state.journeys[state.activeView].cidade,
                state: state.journeys[state.activeView].estado,
                cep: state.journeys[state.activeView].cep,
                logistics_service_id: state.journeys[state.activeView].logistics
            }
        };
        if (state.storeQuery.invUuid) {
            signup.invUuid = state.storeQuery.invUuid;
        }
        if (state.activeView === 'specialPlanSignup') {
            signup.uuid = state.journeys.specialPlanSignup.planEspUuid
        }
        // if (state.journeys[state.activeView].promoCoupon) {
        //     signup = {
        //         ...signup,
        //         promo_coupon_id: state.journeys[state.activeView].promoCoupon.id
        //     };
        // } else {
        //     signup = {
        //         ...signup,
        //         promo_coupon_id: null
        //     };
        // }

        if (rootState.userData.bearer) {
            signup.bearer = rootState.userData.bearer;
        }

        let modalFlag = 'assinatura';
        if (state.activeView === 'specialPlanSignup') {
            modalFlag = 'plano_especial'
        } else if (rootState.userData.is_member) {
            if (rootState.userData.is_plan_active) {
                modalFlag = 'migracao';
            } else {
                modalFlag = 'reativacao';
            }
        }

        Axios.post('/signup', signup)
            .then(response => {
                if (!response.data.error) {
                    const plan = flags.plan
                    const shippingUn = flags.shipping
                    const numberOfPacks = plan.config.number_of_packs || 1

                    const eventParams = {
                        transaction_id: `${rootState.userData.id}_${plan.id}_${Date.now()}`,
                        value: +(plan.price * numberOfPacks + shippingUn * numberOfPacks).toFixed(2),
                        shipping: +(shippingUn * numberOfPacks).toFixed(2),
                        currency: 'BRL',
                        platform: state.storeQuery.fullscreen ? 'mobile' : 'web',
                        user_action: modalFlag,
                        items: [{
                            item_id: `plan_id_${plan.id}`,
                            item_name: plan.name,
                            item_category: plan.database_type,
                            price: +(plan.price * numberOfPacks).toFixed(2)
                        }]
                    };

                    this.$globalFuncs.firebaseProxy('event', 'purchase', eventParams);
                    this.$fb.track('wa_signup', {plan_id: signup.plan_id});

                    if (state.webview || state.storeQuery.fullscreen) {
                        window.location.href = '/webview/success';
                    }
                    commit('updateSending', 'complete'  );
                    dispatch('userData/getUserData', {}, { root: true });
                    dispatch('userData/getPaymentData', {}, { root: true });
                    setTimeout(() => {
                        if (
                          state.activeView === 'specialPlanSignup' &&
                          state.journeys.specialPlanSignup.planInfo.isOneTimeBuy
                        ) {
                            dispatch('openModal', 'productPurchaseFinish');
                        } else {
                            switch (modalFlag) {
                                case "reativacao":
                                    dispatch('openModal', 'signUpFinish');
                                    break;
                                case "migracao":
                                    dispatch('openModal', 'updatePlanSuccess');
                                    break;
                                default:
                                    dispatch('openModal', 'signUpFinish');
                                    break;
                            }
                        }
                        commit('updateSending', null  );
                        commit(flags.action, { field: 'step', value: 0 });
                        dispatch('changeView', 'memories');
                        flags.clearCoupon();
                    }, 1000);
                } else {
                    commit('updateSending', false  );
                    let errorMsg = new Set();
                    if (
                      response.data.msg &&
                      response.data.msg.errors &&
                      response.data.msg.errors.message
                    ) {
                        errorMsg.add(response.data.msg.errors.message);
                    } else if (
                      response.data.msg &&
                      response.data.msg.error
                    ) {
                        errorMsg.add(response.data.msg.error);
                    } else {
                        errorMsg.add('Ocorreu um erro com o serviço')
                    }
                    dispatch(flags.warn, {
                        creator: 'payment',
                        msg: errorMsg
                    })
                    this.$globalFuncs.firebaseProxy('event', 'falha_jor_'+modalFlag);
                }
            })
            .catch(error => {
                this.$errorPrint('/signup', error);
                let errorMsg = new Map('Ocorreu um erro com o serviço')
                commit('updateSending', false  );
                dispatch(flags.warn, {
                    creator: 'payment',
                    msg: errorMsg
                })
            });
    },
    prepareUpdatePlan({ commit, dispatch }, payload) {
        commit('toggleConfOptions', { field: 'planMsg', value: "Alterando seu plano" });
        Axios.post('/buscafrete', {
            cep: payload.cep,
            plan_id: payload.plan_id
        })
            .then(response => {
                dispatch('updatePlan', {
                    plan_id: payload.plan_id,
                    logistics: parseInt(response.data.data.shipping[0].service.id)
                });
            })
            .catch(error => {
                this.$errorPrint('/prepareUpdatePlan', error);
            });
    },
    updatePlan({ commit, dispatch }, payload) {
        Axios.post('/updateplan', { plan_id: payload.plan_id, logistics: payload.logistics })
            .then(response => {
                if (response.data.error) {
                    commit('toggleConfOptions', { field: 'planMsg', value: "Ocorreu um erro, tente novamente" });
                    setTimeout(() => {
                        commit('toggleConfOptions', { field: 'planMsg', value: false });
                    }, 2500);
                } else {
                    commit('toggleConfOptions', { field: 'planMsg', value: "Plano Alterado" });
                    setTimeout(() => {
                        commit('toggleConfOptions', { field: 'planMsg', value: false });
                        commit('closeConfOptions');
                    }, 2000);
                    dispatch('userData/getUserData', {}, { root: true });
                }
            })
            .catch(error => {
                this.$errorPrint('/updateplan', error);
            });
    },
    sendPassword({ commit, dispatch }, payload) {
        commit('toggleConfOptions', { field: 'passwordMsg', value: "Alterando senha" });
        Axios.post('/passwordchange', payload)
            .then(response => {
                if (response.data.error) {
                    if (response.data.msg) {
                        if (response.data.msg.errors && response.data.msg.errors.message) {
                            commit('toggleConfOptions', { field: 'passwordMsg', value: response.data.msg.errors.message });
                        } else {
                            commit('toggleConfOptions', { field: 'passwordMsg', value: "Ocorreu um erro, tente novamente." });
                        }
                    } else {
                        commit('toggleConfOptions', { field: 'passwordMsg', value: "Ocorreu um erro, tente novamente." });
                    }
                    setTimeout(() => {
                        commit('toggleConfOptions', { field: 'passwordMsg', value: false });
                    }, 2500);
                } else {
                    commit('toggleConfOptions', { field: 'passwordMsg', value: "Senha Alterada" });
                    setTimeout(() => {
                        commit('toggleConfOptions', { field: 'passwordMsg', value: false });
                        commit('closeConfOptions');
                    }, 2000);
                    dispatch('userData/getUserData', {}, { root: true });
                }
            })
            .catch(error => {
                this.$errorPrint('/passwordchange', error);
            });
    },
    updateShowPhoto({ commit }, photoKey) {
        commit('updateField', { field: 'showPhoto', value: photoKey });
    },
    setPassRecover({ commit }, payload) {
        commit('updateField', { field: 'passResetHash', value: payload.hash });
        commit('updateField', { field: 'passResetMail', value: payload.userMail });
        commit('updateField', { field: 'activeView', value: 'passRecover' });
    },
    recoverPassword({ commit }, payload) {
        Axios.post('/passwordRecover', payload.data)
            .then(response => {
                if (!response.data.error) {
                    payload.onSuccess();
                } else {
                    payload.onError(response.data);
                    this.$errorPrint('/passwordRecover', response.data);
                }
            })
            .catch(error => {
                throw new Error(error);
                this.$errorPrint('/passwordRecover', error);
                payload.onError(error);
            });
    },
    setAlert({ commit }, payload) {
        commit('createAlertModal', payload);
    },
    dismissAppInfo({commit}) {
        Axios.get('/dismissAppInfo')
          .then(response => {
            commit('userData/updateField', {field: 'dismissAppInfo', value: true}, {root: true});
          })
    },
    toggleUploadHUB({state, commit}, payload) {
        commit('updateField', {
            field: 'uploadHUB',
            value: payload
        })
    },
    setVisualIdentityCookie({commit}, identity) {
        Axios.get('/setVisualIdentityCookie', {
            params: {
                identity: identity
            }
        })
          .then(response => {
              if (response.data.success) {
                  commit('userData/updateField', {field: 'visualIdentityModifier', value: identity}, {root: true});
              }
          })
    },
};

export const mutations = {
    updateField(state, payload) {
        if (payload.parent) {
            if (payload.parent[1]) {
                state[payload.parent[0]][payload.parent[1]][payload.field] = payload.value;
            } else if (payload.parent[0]) {
                state[payload.parent[0]][payload.field] = payload.value;
            } else {
                state[payload.parent][payload.field] = payload.value;
            }
        } else {
            state[payload.field] = payload.value;
        }
    },
    updateSignupField(state, payload) {
        state.journeys.signup[payload.field] = payload.value;
    },
    updateSpecialPlanSignupField(state, payload) {
        state.journeys.specialPlanSignup[payload.field] = payload.value;
    },
    updateGiftcardField(state, payload) {
        state.journeys.giftcard[payload.field] = payload.value;
    },
    updateSending(state, payload) {
        state.journeys.sending = payload;
    },
    updateCaptcha(state, payload) {
        state.captchaTokens[payload.field] = payload.value;
    },
    updateStoreQuery(state, payload) {
        state.storeQuery[payload.field] = payload.value;
    },
    clearArea(state) {
        state.uploadArea = false;
    },
    openUploadArea(state) {
        state.uploadArea = true;
    },
    toggleUploadArea(state) {
        state.uploadArea = !state.uploadArea;
    },
    changePhotoSize(state, payload) {
        state.photoAspect = payload.value;
    },
    createModal(state, payload) {
        state.modalControl.open = true;
        state.modalControl.type = payload.type;
    },
    preventEscape(state, payload) {
        state.modalControl.preventEscape = payload;
    },
    createAlertModal(state, payload) {
        state.modalControl = {
            open: true,
            type: 'alert',
            alert: {
                icon: payload.icon || null,
                title: payload.title || null,
                call: payload.call || null,
                text: payload.text || null,
                btn: payload.btn || 'Ok',
                color: payload.color || 'warn',
            }
        };
    },
    closeAllModal(state) {
        state.modalControl = {
            open: false,
            type: 'alert',
            alert: {
                icon: 'phosficon-watchout',
                title: 'Ops...',
                text: 'Ocorreu um erro inesperado.',
                btn: 'OK!'
            }
        };
    },
    toggleConfOptions(state, payload) {
        state.configOptions[payload.field] = payload.value;
    },
    closeConfOptions(state) {
        state.configOptions = {
            address: false,
            password: false,
            payment: false,
            plan: false,
            addressMsg: false,
            passwordMsg: false,
            paymentMsg: false,
            planMsg: false,
            loyaltyPlanHelp: false,
            loyaltyPlanHelpMsg: false,
            generalHelp: false,
            generalHelpMsg: false
        };
    },
    igProcessing(state, value) {
        state.instagram_processing = value;
    },
    setSignupStep(state, value) {
        if (value === '+') {
            state.journeys.signup.step++;
        } else if (value === '-') {
            state.journeys.signup.step--;
        } else {
            state.journeys.signup.step = value;
        }
    },
    setSpecialPlanSignupStep(state, value) {
        if (value === '+') {
            state.journeys.specialPlanSignup.step++;
        } else if (value === '-') {
            state.journeys.specialPlanSignup.step--;
        } else {
            state.journeys.specialPlanSignup.step = value;
        }
    }
};
